import { APP_BASE_URL, MILISECONDS_IN_ONE_DAY } from "./shared.constants";
import { PageType, VaccineIds, Vaccines } from "./shared.enums";
import { Vaccine } from "./shared.models";
import { PageDetails } from "../components/vaccination/vaccination.models";
import { RoleIds } from "../components/user/user.enums";

export function Redirect(uri) {
  const url = `${APP_BASE_URL}${uri}`;
  window.location.assign(url);
}

export function GetPageDetails(path): PageDetails {
  const pathArray = path.split('/');
  if (pathArray.length && pathArray.length > 1) {
    const details = {} as PageDetails;
    const lastValue = pathArray[pathArray.length - 1];
    if (isNaN(lastValue)) {
      details.PageType = lastValue;
    } else {
      details.PageType = pathArray[pathArray.length - 2];

      if (details.PageType == PageType.Add) {
        details.ParentId = lastValue;
      } else {
        details.Id = lastValue;
      }
    }
    return details;
  }

  throw new Error(`No page title found for path: ${path}`);
}


/**
 * Converts a string to title case. For example, 'LONDON ROAD' becomes 'London Road'.
 *
 * @param str: The string to convert.
 * @returns The title case string.
 */
export function toTitleCase(str: string): string {
  if (!str) return str;

  const lowerCaseWords = ['and', 'or', 'but', 'nor', 'so', 'for', 'yet', 'a', 'an', 'the', 'in', 'on', 'at', 'to', 'by', 'with', 'from', 'of'];

  return str
    .toLowerCase()
    .split(' ')
    .map((word, index) => {
      if (word === 'nhs') {
        return 'NHS';
      }
      if (index === 0 || !lowerCaseWords.includes(word)) {
        return word.charAt(0).toUpperCase() + word.slice(1);
      } else {
        return word;
      }
    })
    .join(' ');
}


export function toSentenceCase(str) {
  if (!str) return str;
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

/**
 * Converts an ISO date string to UK date string.
 *
 * @param  dateOfBirth: ISO date string.
 * @param  dateTo: ISO date string. Optional, if no date is supplied, today's date is used.
 * @returns number.
 */
export function GetAge(dateOfBirthStr: string, dateToStr: string = null): number {
  if (!dateOfBirthStr) throw new Error('No date of birth supplied!');

  var dateOfBirth = new Date(dateOfBirthStr);
  var dateTo = dateToStr ? new Date(dateToStr) : new Date();
  const yearDifference = dateTo.getFullYear() - dateOfBirth.getFullYear();
  var monthDifference = dateTo.getMonth() - dateOfBirth.getMonth();
  var age = yearDifference;

  if (monthDifference < 0 || (monthDifference === 0 && dateTo.getDate() < dateOfBirth.getDate())) {
    age--;
  }

  if (age === 0) age = Number(((monthDifference + (12 * yearDifference)) / 12).toFixed(2));

  return age;
}

export function isEmpty(object) {
  for (const property in object) {
    if (Object.hasOwn(object, property)) {
      return false;
    }
  }

  return true;
}

/**
 * Converts an boolen value to Yes or No.
 *
 * @param  value boolean.
 */
export function BooleanToYesNo(value): string {
  if (value === true || value === 'true') return 'Yes';
  if (value === false || value === 'false') return 'No';
  return value;
}

/**
 * Converts an ISO date string to UK date string.
 *
 * @param  ISO date string.
 * @returns UK date string.
 */
export function IsoToUkDate(dateStr: string): string {
  if (dateStr) {
    const date = new Date(dateStr);
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
  }
  return dateStr;
}

export function DateToLongFormat(dateStr: string): string {
  const date = new Date(dateStr);
  const options: Intl.DateTimeFormatOptions = {day: "numeric", month:"long", year:"numeric"};
  return date.toLocaleDateString('en-GB', options).replace(/,/g,'');
}

export function DateToLongFormatWithDay(dateStr: string): string {
  const date = new Date(dateStr);
  const options: Intl.DateTimeFormatOptions = {weekday: 'long', day: "numeric", month:"long", year:"numeric"};
 return date.toLocaleDateString('en-GB', options).replace(/,/g,'');
}


/**
 * Removes the time from an ISO date time string.
 *
 * @param  ISO date and time string.
 * @returns date string.
 */
export function RemoveTime(dateStr: string): string {
  if (dateStr) {
    const dateTimeArr = dateStr.split('T');
    if (dateTimeArr?.length) return dateTimeArr[0];
  }
  throw Error(`Incorrect date string`);
}

/**
 * Calculates the difference in between two days.
 *
 * @param  dateFrom string.
 * @param  dateTo string (optional). If no dateTo is supplied, today's date is used.
 * @returns number of days.
 */
export function DayDifference(dateFromStr: string, dateToStr: string = null): number {
  const dateFrom = new Date(dateFromStr);
  const dateTo = dateToStr ? new Date(dateToStr) : new Date();
  const diferenceInMiliseconds = (dateTo.valueOf() - dateFrom.valueOf());
  return Math.round(diferenceInMiliseconds / MILISECONDS_IN_ONE_DAY);
}

/**
 * Converts an ISO date to 'ddd DD MMM YYYY' format
 *  
 * @param ISO date string.
 * @returns Split date string
 */
export function IsoToDateSplit(value) {
  if (value) {
    let date = new Date(value);
    const dayOfWeek = date.toLocaleString('default', { weekday: 'short' });
    const day = date.toLocaleString('default', { day: '2-digit' });
    const month = date.toLocaleString('default', { month: 'short' });
    const year = date.toLocaleString('default', { year: 'numeric' });
    return dayOfWeek + ' ' + day + ' ' + month + ' ' + year;
  }
  else return {};
}

/**
 * Converts an ISO date to 'DD MMMM YYYY' format
 *  
 * @param ISO date string.
 * @returns Split date string
 */
export function IsoToDateFornat(dateStr: string): string {
  if (dateStr) {
    let date = new Date(dateStr);
    const day = date.toLocaleString('default', { day: '2-digit' });
    const month = date.toLocaleString('default', { month: 'long' });
    const year = date.toLocaleString('default', { year: 'numeric' });
    return day + ' ' + month + ' ' + year;
  }
  return dateStr;
}

/**
 * Converts an Date type to 'DD MMMM YYYY' string format
 *
 * @param date date string.
 *  @returns 'DD MMMM YYYY'
 */
export function FormateDate(date: Date): string {
    const dateTimeFormat: Intl.DateTimeFormatOptions = {
        day: '2-digit',
        month: 'long',
        year: 'numeric'
    };
    return date.toLocaleDateString('en-GB', dateTimeFormat);
}

/**
 * Checks date is valid
 *  
 * @param date Date.
 * @returns boolean
 */
export function IsValidDate(d) {
  return !isNaN(d);
}

/**
 * Checks value is a number
 *  
 * @param value any.
 * @returns boolean
 */
export function IsNumber(value) {
  return typeof value === 'number';
}

export function ScrollToTop() {
  window.scrollTo(0, 0);
}

export function ScrollToBottom() {
  window.scrollTo(0, document.body.scrollHeight);
}

/**
 * Compares two dates
 *  
 * @param dateFrom Date.
 * @param dateTo Date.
 * @returns boolean
 */
export function CompareDatesIsGreater(dateFrom, dateTo): boolean {
  if (dateFrom > dateTo)
    return true

  return false
}

//#region Covid Vaccine Program
export function covidVaccines(): Vaccine[] {
  return [
    //Pfizer
    { VaccineId: VaccineIds.PfizerCovidOriginalOmicronBA4to5, Name: Vaccines.PfizerCovidOriginalOmicronBA4to5, HasDefrosts: true },
    { VaccineId: VaccineIds.PfizerComirnatyOmicronXBBAge12Plus, Name: Vaccines.PfizerComirnatyOmicronXBBAge12Plus, HasDefrosts: true },
    { VaccineId: VaccineIds.PfizerComirnatyOmicronXBB5To11Years, Name: Vaccines.PfizerComirnatyOmicronXBB5To11Years, HasDefrosts: true },
    { VaccineId: VaccineIds.PfizerComirnatyOmicronXBB6monthsTo4Years, Name: Vaccines.PfizerComirnatyOmicronXBB6monthsTo4Years, HasDefrosts: true },

    //Moderna
    { VaccineId: VaccineIds.ModernaSpikevaxXBB, Name: Vaccines.ModernaSpikevaxXBB, HasDefrosts: true }
  ];
}
//endregion

//#region batch HasDefrost
export function HasDefrosts(vaccineId: number) {
  return covidVaccines().find(v => v.VaccineId == vaccineId)?.HasDefrosts;
}
//#endregion

export function RemoveValues(formik, fields: string[]) {
  for (const f of fields) {
    formik.setFieldValue(f, '');
  }
}

export function UpdateValues(formik, model) {
  const fields = Object.keys(model);
  for (const f of fields) {
    formik.setFieldValue(f, model[f]);
  }
}

/**
 * Groups an array by key value
 *  
 * @param array: any.
 * @param groupByKey: string.
 * @returns object array of arrays: [{groupbyKey, groupedArray}]
 */
export function GroupBy(xs, f) {
  return xs.reduce((r, v, i, a, k = f(v)) => ((r[k] || (r[k] = [])).push(v), r), {});
}

/**
 * Calculates years difference between two dates
 *  
 * @param dateold: Date.
 * @param datenew: Date.
 * @returns difference in years
 */
export function DateDiffInYears(dateold, datenew): number {
  var ynew = datenew.getFullYear();
  var mnew = datenew.getMonth();
  var dnew = datenew.getDate();
  var yold = dateold.getFullYear();
  var mold = dateold.getMonth();
  var dold = dateold.getDate();
  var diff = ynew - yold;
  if (mold > mnew) diff--;
  else {
    if (mold === mnew) {
      if (dold > dnew) diff--;
    }
  }
  return diff;
}

/**
 * Calculates days difference between two dates
 *  
 * @param dateold: Date.
 * @param datenew: Date.
 * @returns difference in days: string
 */
export function DateDiffInDays(d1, d2): string {
  d1 = d1.getTime() / 86400000;
  d2 = d2.getTime() / 86400000;
  return new Number(d2 - d1).toFixed(0);
}

/**
 * Three text fields DD MM YYYY focus change
 * Moves focus to next input field onChange event
 */
export function HandleFocusChange(event) {
  const { maxLength, value, name } = event.target;
  const [fieldName, fieldIndex] = name.split("_");

  // Check if they hit the max character length
  if (value.length >= maxLength) {
    // Check if it's not the last input field
    if (parseInt(fieldIndex, 9) < 3) {

      const nextSibling = document.querySelector<HTMLInputElement>(`input[name=${fieldName}_${parseInt(fieldIndex, 9) + 1}]`);

      // If found, focus the next field
      if (nextSibling !== null) {
        nextSibling?.focus();
      }
    }
  }
}

/**
* Formats NHS Number to XXX XXX XXXX
*  
* @param NHS Number.
* @returns Formatted NHS number
*/
export function FormatNhsNumber(nhsNumber: string): string {
  if (nhsNumber && nhsNumber.length >= 10) {
    return nhsNumber.substring(0, 3) + ' ' + nhsNumber.substring(3, 6) + ' ' + nhsNumber.substring(6);
  }
  return nhsNumber;
}

/**
* Limits lenth of numeric inputs
* maxLength not working for number input type but works for text type
*  
* @param event
* @param formik
* @param maxLength
*/
export function HandleValueLength(e, formik, maxLength) {
    let value = e.target.value;
    const name = e.target.name;
    if (value.length > maxLength) {
        formik.setFieldValue(name, value.slice(0, maxLength));
    }
}

/**
 * Formats a string array to a string with commas
 *
 * @param arr: string[].
 * @returns fomatted string
 * @example ['cat','dog', 'pig'] => 'cat, dog and pig'
 */

export function formatStringList(arr: string[]): string {
    if (arr.length === 0) return "";
    if (arr.length === 1) return arr[0];
    if (arr.length === 2) return arr.join(" and ");
    return arr.slice(0, -1).join(", ") + " and " + arr[arr.length - 1];
}

/**
 * Matches a given role name with its corresponding role ID.
 * 
 * @param {string} roleName - The name of the role to be matched.
 * @returns {string | undefined} - The corresponding role ID if a match is found, otherwise undefined.
 */
export const matchRoleWithRoleId = (roleName: string): string | undefined => {
  const normalizedRoleName = roleName.replace(/\s+/g, "").toLowerCase();
  const matchedKey = Object.keys(RoleIds).find(
    (key) => normalizedRoleName === key.toLowerCase()
  );
  return matchedKey ? RoleIds[matchedKey as keyof typeof RoleIds] : undefined;
};

/**
 *  Returns last login status based on lastLoggedIn
 * 
 * This function determines the user's status based on whether the `lastLoggedIn` timestamp
 * is `null` or not. If the timestamp is not `null`, the user is considered "Active". If it
 * is `null`, the user is considered "Invited".
 * 
 * @param {string | null} lastLoggedIn - The timestamp of the last time the user logged in, or `null`
 *                                       if the user has not logged in yet.
 * @returns {string} - Returns "Active" if `lastLoggedIn` is not `null`, otherwise returns "Invited".
 */
export const UserStatusConverter = (lastLoggedIn: string | null): string => {
  if (lastLoggedIn !== null) {
      return "Active";
  } else {
      return "Invited";
  }
}

/**
 * Smoothly scrolls to an element and sets focus on it.
 * 
 * Finds the HTML element with the specified `elementId`, scrolls smoothly to it, and focuses on it.
 * If the element is not found, no action is performed.
 * 
 * @param {string} elementId - The `id` of the HTML element to scroll to and focus on.
 * @returns {void} - No return value.
 */
export const scrollToElementId = (elementId: string) => {
  const element = document.getElementById(elementId);
  if (element) {
    element.scrollIntoView({ behavior: 'smooth' });
    element.focus();
  }
};

/**
 * Truncates a string to a specified length and appends an ellipsis if necessary.
 * 
 * @param {string} content - The string to truncate.
 * @param {number} [maxLength=50] - The maximum length of the string before truncation.
 * @returns {string} - The truncated string with an ellipsis, or the original string if no truncation is needed.
 */
export function TruncateContent(content: string, maxLength: number = 50): string {
  return content.length > maxLength ? `${content.substring(0, maxLength)}...` : content;
}


export function DateToShortFormat(dateStr: string): string {
  if (dateStr) {
      const date = new Date(dateStr);
      const options: Intl.DateTimeFormatOptions = { day: "numeric", month: "short", year: "numeric" };
      return date.toLocaleDateString('en-GB', options).replace(/,/g, '');
  }
  return dateStr;
}
/**
 * Handles Errors link onlick event to focus on the field element
 * @param e (event)
 * @param fieldName
 */
export function HandleErrorFocusOnClickByName(e, fieldName){
    e.preventDefault();
    document.getElementsByName(fieldName)[0]?.focus();
}

export function HandleErrorFocusOnClickById(e, id) {
    e.preventDefault();
    document.getElementById(id)?.focus();
}
