import React, { useEffect, useState } from 'react';
import userService from '../user.service';
import useAnalytics from '../../analytics/hooks/useAnalytics';
import useDocumentTitle from '../../../_shared/hooks/useDocumentTitle';
import { Category } from '../../analytics/analytics.models';
import { UserPageTitles, UserProfilePrimaryCategory } from '../user.enums';

export default function Profile() {
    useAnalytics({ primaryCategory: UserProfilePrimaryCategory.PrimaryCategory } as Category);
    useDocumentTitle(UserPageTitles.UserProfile);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const subscription = userService.subscribe(() => populateState())

    populateState()

    return () => { userService.unsubscribe(subscription); }
  }, [])

  const populateState = async () => {
    setUser(await userService.getUser$());
  }

  return (
    <>
      { user ? (
          <div className="card mb-2">
            <div className="card-body">
              <h2>Profile </h2>
              <hr />

              <div className="row mb-3">
                <div className="col-md-3">
                  <label htmlFor="FirstName">First name</label>
                  <input type="text" id="FirstName" className="form-control" value={user.FirstName || ""} disabled={true} />
                </div>
                
                <div className="col-md-3">
                  <label htmlFor="Surname">Surname</label>
                  <input type="text" id="Surname" className="form-control" value={user.LastName || ""} disabled={true} />
                </div>
                <div className="col-md-6">
                  <label htmlFor="Email">Email</label>
                  <input type="text" id="Email" className="form-control" value={user.Email || ""} disabled={true} />
                </div>
              </div>

              <div className="row">
                <div className="col-md-7">
                  <label htmlFor="Organisation">Organisation</label>
                  <input type="text" id="Organisation" value={user.OrganisationId || ""} className="form-control" disabled={true} />
                </div>

                <div className="col-md-5">
                  <label htmlFor="AccountStatusId">Account Status</label>
                  <input id="AccountStatusId" className="form-control" value={user.AccountStatusId || ""} disabled={true} />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="pt-4 pb-4 background-white">
            <div className="alert alert-primary" role="alert">
                Not signed in!
            </div>
          </div>
        )
      }
    </>
    );
  }
