import * as React from 'react'
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useFormik } from 'formik';
import { number, object, string } from 'yup';
import { Button } from 'reactstrap';
import useDocumentTitle from '../../_shared/hooks/useDocumentTitle';
import useAnalytics from '../analytics/hooks/useAnalytics';
import { AddBatchAnalyticsSubCategories, SiteVaccinesAnalyticsPageNames, SiteVaccinesContants, SiteVaccinesPageTitles, SiteVaccinesPaths } from './site-vaccines.enums';
import { HandleErrorFocusOnClickByName, HandleFocusChange, Redirect, RemoveTime } from '../../_shared/shared.functions';
import { Paths } from '../../_shared/shared.enums';
import { SiteVaccines } from './site-vaccines.models';
import { Category } from '../analytics/analytics.models';
import { BatchDto, ExistingSiteVaccineBatch } from '../batch/batch.models';
import { DisplayVaccineHintText } from './site-vaccines.functions';
import { IsInvalidBatch } from '../batch/batch.functions';
import siteVaccinesService from './site-vaccines.service';

export default function AddEditBatch() {
    const location = useLocation();
    const navigate = useNavigate();

    const redirect = () => {
        Redirect(Paths.Home);
    }

    const optionsStateData = location && location.state ? location.state[0] as any : redirect();
    const userSitesStateData = location && location.state ? location.state[1] as any : redirect();
    const siteVaccinesStateData = location && location.state ? location.state[2] as SiteVaccines[] : redirect();
    const batchStateData = location && location.state ? location.state[3] as BatchDto : redirect();
    const siteVaccineBatchesStateData = location && location.state ? location.state[4] as BatchDto[] : redirect();
    const addOrEditStateData = location && location.state ? location.state[5] as any : redirect();
    const depleteOrUndepleteStateData = location && location.state ? location.state[6] as any : redirect();
    const siteVaccineStateData = location && location.state ? location.state[7] as SiteVaccines : redirect();

    const [options, setOptions] = React.useState(optionsStateData as any);
    const [userSites] = React.useState(userSitesStateData);
    const [siteVaccines, setSiteVaccines] = React.useState(siteVaccinesStateData as SiteVaccines[]);
    const [batch, setBatch] = React.useState(batchStateData as BatchDto);
    const [siteVaccineBatches, setSiteVaccineBatches] = React.useState(siteVaccineBatchesStateData as BatchDto[]);
    const [addOrEdit] = React.useState(addOrEditStateData as any);
    const [depleteOrUndeplete] = React.useState(depleteOrUndepleteStateData as any);
    const [siteVaccine] = React.useState(siteVaccineStateData as SiteVaccines);

    const [existingSiteVaccineBatch, setExistingSiteVaccineBatch] = React.useState(null as ExistingSiteVaccineBatch);

    let pageAction = SiteVaccinesPageTitles.AddBatch
    pageAction = addOrEdit?.IsEditBatch ? SiteVaccinesPageTitles.EditBatch : pageAction
    useDocumentTitle(pageAction);

    let subCategory2 = AddBatchAnalyticsSubCategories.SubCategory2Add;
    subCategory2 = addOrEdit?.IsEditBatch ? AddBatchAnalyticsSubCategories.SubCategory2Edit : subCategory2
    useAnalytics({ primaryCategory: SiteVaccinesAnalyticsPageNames.PrimaryCategory, subCategory1: AddBatchAnalyticsSubCategories.SubCategory1, subCategory2: subCategory2 } as Category);

    const [showInvalidBatchFormatWarning, setShowInvalidBatchFormatWarning] = React.useState(false);
    const [showCovidBatchNumberFields, setShowCovidBatchNumberFields] = React.useState(false);
    const [showOtherBatchNumberField, setShowOtherBatchNumberField] = React.useState(false);
    const [batchExistsError, setBatchExistsError] = React.useState(false);
    const [vaccineHintText, setVaccineHintText] = React.useState('');

    let batchExpiryDate = batch && batch?.ExpiryDate?.includes('T') ? RemoveTime(batch?.ExpiryDate) : batch && !batch?.ExpiryDate?.includes('T') ? batch?.ExpiryDate : ''

    function CheckCovidBatchNumberExistsInSite(vaccineId: number, site: string, covidBatchNumberValue: string, siteVaccineBatches: BatchDto[]) {
        siteVaccineBatches = (addOrEdit && addOrEdit?.IsEditBatch)
            ? siteVaccineBatches.filter(svb => svb.BatchNumber?.toUpperCase() !== batch?.BatchNumber?.toUpperCase())
            : siteVaccineBatches

        siteVaccineBatches?.forEach((siteVaccineBatch: BatchDto) => {
            if (siteVaccineBatch.VaccineId === vaccineId && siteVaccineBatch.Site === site && siteVaccineBatch.BatchNumber?.toUpperCase() === covidBatchNumberValue) {
                setBatchExistsError(true)
            }
        });
    }
    function CheckOtherBatchNumberExistsInSite(vaccineId: number, site: string, otherBatchNumberValue: string, siteVaccineBatches: BatchDto[]) {
        siteVaccineBatches = (addOrEdit && addOrEdit?.IsEditBatch)
            ? siteVaccineBatches.filter(svb => svb.BatchNumber?.toUpperCase() !== batch?.BatchNumber?.toUpperCase())
            : siteVaccineBatches

        siteVaccineBatches?.forEach((siteVaccineBatch: BatchDto) => {
            if (siteVaccineBatch.VaccineId === vaccineId && siteVaccineBatch.Site === site && siteVaccineBatch.BatchNumber?.toUpperCase() === otherBatchNumberValue) {
                setBatchExistsError(true)
            }
        });
    }

    const formik = useFormik({
        initialValues: {
            VaccineProgramId: batch?.VaccineProgramId.toString(),
            CovidBatchNumberPrefix: batch?.VaccineProgramId === SiteVaccinesContants.COVID19_ID ? batch?.BatchNumber?.split('-')[0]?.toUpperCase() : '',
            CovidBatchNumberSuffix: batch?.VaccineProgramId === SiteVaccinesContants.COVID19_ID ? batch?.BatchNumber?.split('-')[1]?.toUpperCase() : '',
            OtherBatchNumber: batch?.VaccineProgramId !== SiteVaccinesContants.COVID19_ID ? batch?.BatchNumber?.toUpperCase() : '',
            ExpiryDate_1: batch ? batchExpiryDate?.split('-')[2] : '',
            ExpiryDate_2: batch ? batchExpiryDate?.split('-')[1] : '',
            ExpiryDate_3: batch ? batchExpiryDate?.split('-')[0] : '',
        },

        validationSchema: object().shape({
            VaccineProgramId: string(),
            CovidBatchNumberPrefix: string().when(['VaccineProgramId'], {
                is: (VaccineProgramId) => VaccineProgramId === SiteVaccinesContants.COVID19_ID.toString(),
                then: schema => schema.required("Enter the batch number")
            }),
            CovidBatchNumberSuffix: string().when(['VaccineProgramId'], {
                is: (VaccineProgramId) => VaccineProgramId === SiteVaccinesContants.COVID19_ID.toString(),
                then: schema => schema.required("Second field required"),
            }),
            OtherBatchNumber: string().when(['VaccineProgramId'], {
                is: (VaccineProgramId) => VaccineProgramId !== SiteVaccinesContants.COVID19_ID.toString(),
                then: schema => schema.required("Enter the batch number")
            }),
            ExpiryDate_1: number().test('ExpiryDate_1', 'Enter the thawed expiry date', (expDate_1, context) => {
                const vaccineProgramId = context.parent?.VaccineProgramId;
                const expiryDate_2 = context.parent?.ExpiryDate_2;
                const expiryDate_3 = context.parent?.ExpiryDate_3;
                if ((!expDate_1 || !expiryDate_2 || !expiryDate_3) && vaccineProgramId === SiteVaccinesContants.COVID19_ID.toString())
                    return false;
                return true;
            }).test('ExpiryDate_1', 'Enter the thawed expiry date in the correct format', (expiryDate_1, context) => {
                const day = context.parent?.ExpiryDate_1;
                const month = context.parent?.ExpiryDate_2;
                const year = context.parent?.ExpiryDate_3;
                const vaccineProgramId = context.parent?.VaccineProgramId;

                if (vaccineProgramId === SiteVaccinesContants.COVID19_ID.toString()) {
                    if (/^(d{1,2})$/.test(day)) {
                        return false;
                    }

                    if (/^(d{1,2})$/.test(month)) {
                        return false;
                    }

                    if (/^(d{2,4})$/.test(year)) {
                        return false;
                    }

                    if (year?.toString()?.length <= 3 || year?.toString()?.length > 4)
                        return false

                    if (Number(month) < 1 || Number(month) > 12) {
                        return false;
                    }

                    if (Number(day) < 1 || Number(day) > 31) {
                        return false;
                    }

                    if ((Number(month) === 4 || Number(month) === 6 || Number(month) === 9 || Number(month) === 11) && Number(day) === 31) {
                        return false;
                    }

                    if (Number(month) === 2) {
                        const isLeapYear = ((Number(year) % 4 === 0 && Number(year) % 100 !== 0) || Number(year) % 400 === 0);

                        if ((Number(day) > 29 || (Number(day) === 29 && !isLeapYear))) {
                            return false;
                        }
                    }
                }

                return true;
            }).test('ExpiryDate_1', 'Thawed expiry date must be in the future', (expDate_1, context) => {
                const expiryDate_2 = context.parent?.ExpiryDate_2;
                const expiryDate_3 = context.parent?.ExpiryDate_3;
                const vaccineProgramId = context.parent?.VaccineProgramId;

                if (vaccineProgramId === SiteVaccinesContants.COVID19_ID.toString()) {
                    const inputDate = new Date(expiryDate_3 + '-' + expiryDate_2 + '-' + expDate_1);
                    const currentDate = new Date();
                    const allowedDate = new Date(currentDate.setDate(currentDate.getDate() - 1));

                    if (inputDate <= allowedDate) {
                        return false;
                    }
                }

                return true;
            }).test('ExpiryDate_1', 'Enter the expiry date', (expDate_1, context) => {
                const vaccineProgramId = context.parent?.VaccineProgramId;
                const expiryDate_2 = context.parent?.ExpiryDate_2;
                const expiryDate_3 = context.parent?.ExpiryDate_3;
                if ((!expDate_1 || !expiryDate_2 || !expiryDate_3) && vaccineProgramId !== SiteVaccinesContants.COVID19_ID.toString())
                    return false;
                return true;
            })
                .test('ExpiryDate_1', 'Enter the expiry date in the correct format', (expiryDate_1, context) => {
                    const day = context.parent?.ExpiryDate_1;
                    const month = context.parent?.ExpiryDate_2;
                    const year = context.parent?.ExpiryDate_3;
                    const vaccineProgramId = context.parent?.VaccineProgramId;

                    if (vaccineProgramId !== SiteVaccinesContants.COVID19_ID.toString()) {
                        if (/^(d{1,2})$/.test(day)) {
                            return false;
                        }

                        if (/^(d{1,2})$/.test(month)) {
                            return false;
                        }

                        if (/^(d{2,4})$/.test(year)) {
                            return false;
                        }

                        if (year?.toString()?.length <= 3 || year?.toString()?.length > 4)
                            return false

                        if (Number(month) < 1 || Number(month) > 12) {
                            return false;
                        }

                        if (Number(day) < 1 || Number(day) > 31) {
                            return false;
                        }

                        if ((Number(month) === 4 || Number(month) === 6 || Number(month) === 9 || Number(month) === 11) && Number(day) === 31) {
                            return false;
                        }

                        if (Number(month) === 2) {
                            const isLeapYear = ((Number(year) % 4 === 0 && Number(year) % 100 !== 0) || Number(year) % 400 === 0);

                            if ((Number(day) > 29 || (Number(day) === 29 && !isLeapYear))) {
                                return false;
                            }
                        }
                    }

                    return true;
                }).test('ExpiryDate_1', 'Expiry date must be in the future', (expDate_1, context) => {
                    const expiryDate_2 = context.parent?.ExpiryDate_2;
                    const expiryDate_3 = context.parent?.ExpiryDate_3;
                    const vaccineProgramId = context.parent?.VaccineProgramId;

                    if (vaccineProgramId !== SiteVaccinesContants.COVID19_ID.toString()) {
                        const inputDate = new Date(expiryDate_3 + '-' + expiryDate_2 + '-' + expDate_1);
                        const currentDate = new Date();
                        const allowedDate = new Date(currentDate.setDate(currentDate.getDate() - 1));

                        if (inputDate <= allowedDate) {
                            return false;
                        }
                    }

                    return true;
                })
        }),

        enableReinitialize: true,

        onSubmit: (async (values) => {
            if (!batchExistsError) {
                let batchData = values as unknown as BatchDto;

                batchData = batch ? batch : batchData

                switch (batchData.VaccineProgramId) {
                    case (SiteVaccinesContants.COVID19_ID): {
                        batchData.BatchNumber = values.CovidBatchNumberPrefix + '-' + values.CovidBatchNumberSuffix;
                        break;
                    }
                    default: {
                        batchData.BatchNumber = values.OtherBatchNumber
                        break;
                    }
                }

                batchData.ExpiryDate = values.ExpiryDate_3 + '-' + values.ExpiryDate_2 + '-' + values.ExpiryDate_1;

                setBatch(batchData);

                if (addOrEdit && addOrEdit?.IsEditBatch) {
                    //perform api edit update
                    var returnedResult = await siteVaccinesService.editSiteVaccineBatch$(batchData);

                    setSiteVaccines(returnedResult.SitesVaccines)
                    let updatedOptions = options
                    updatedOptions.Sites = returnedResult.Sites;
                    setOptions(prevState => ({
                        ...prevState,
                        Sites: returnedResult.Sites,
                    }));
                    navigate(SiteVaccinesPaths.SiteVaccineBatchesList, { state: [updatedOptions, returnedResult.Sites, returnedResult.SitesVaccines, returnedResult.SitesVaccines.find(sv => sv.SiteVaccineId === siteVaccine.SiteVaccineId) as SiteVaccines] });
                }
                else {
                    //continue to confirmPage (add)
                    navigate(SiteVaccinesPaths.ConfirmSiteVaccineBatch, { state: [options, userSites, siteVaccines, batchData, siteVaccineBatches, addOrEdit, null, siteVaccine] });
                }
            }
        })
    });

    React.useEffect(() => {
        if (batch) {
            if (batch?.VaccineProgramId === SiteVaccinesContants.COVID19_ID) {
                setShowCovidBatchNumberFields(true);
            }
            else if (batch?.VaccineProgramId !== SiteVaccinesContants.COVID19_ID) {
                setShowOtherBatchNumberField(true);
            }
            setVaccineHintText(DisplayVaccineHintText(Number(batch?.VaccineId)));

            const checkAddressExists = async () => {
                if (!batch.AddrLn1) {
                    let orgDetails = await siteVaccinesService.nhsdOrganisation$(batch.Code)

                    setBatch(prevState => ({
                        ...prevState,
                        AddrLn1: orgDetails?.AddressLine1,
                        AddrLn2: orgDetails?.AddressLine2,
                        Town: orgDetails?.Town,
                        County: orgDetails?.County,
                        PostCode: orgDetails?.PostCode
                    }));
                }
            }

            checkAddressExists();
        }
    }, [batch]);

    const handleBatchNumberChange = (e, data) => {
        setBatchExistsError(false);

        let covidBatchNumberPrefix = formik.values.CovidBatchNumberPrefix;
        let covidBatchNumberSuffix = formik.values.CovidBatchNumberSuffix;
        let otherBatchNumber = formik.values.OtherBatchNumber;

        if (data.IsCovidBatchNumberPrefix) {
            covidBatchNumberPrefix = e.target?.value;
        }
        else if (data.IsCovidBatchNumberSuffix) {
            covidBatchNumberSuffix = e.target?.value;
        }
        else if (data.IsOtherBatchNumber) {
            otherBatchNumber = e.target?.value;
        }

        const vaccineId = batch?.VaccineId;
        const vaccineProgramId = batch?.VaccineProgramId;

        CheckBatchNumberValid(vaccineProgramId, vaccineId, data, covidBatchNumberPrefix);

        switch (vaccineProgramId) {
            case (SiteVaccinesContants.COVID19_ID): {
                //add journey from 'Add batch' button in SiteVaccinesList or site-vaccine-batches page
                if ((addOrEdit?.IsAddBatchFromSiteVaccinesList || addOrEdit?.IsAddBatchFromSiteBatchesList || addOrEdit?.IsEditBatch) && vaccineProgramId.toString() === SiteVaccinesContants.COVID19_ID.toString() && covidBatchNumberPrefix && covidBatchNumberSuffix && siteVaccineBatches?.length > 0) {
                    CheckCovidBatchNumberExistsInSite(batch?.VaccineId, batch?.Site, covidBatchNumberPrefix + "-" + covidBatchNumberSuffix, siteVaccineBatches);
                }

                //add journey from 'Add vaccine' button in SiteVaccinesList page
                if (((addOrEdit?.IsAddBatch && !addOrEdit?.IsAddBatchFromSiteVaccinesList && !addOrEdit?.IsAddBatchFromSiteBatchesList) || addOrEdit?.IsEditBatch) && vaccineProgramId.toString() === SiteVaccinesContants.COVID19_ID.toString() && covidBatchNumberPrefix && covidBatchNumberSuffix && siteVaccines?.length > 0) {
                    siteVaccines?.filter(sv => sv.VaccineProgramId === vaccineProgramId).forEach((siteVaccine: SiteVaccines) => {
                        CheckCovidBatchNumberExistsInSite(batch?.VaccineId, batch?.Site, covidBatchNumberPrefix + "-" + covidBatchNumberSuffix, siteVaccine?.SiteVaccineBatches);
                    })
                }
                break;
            }
            default: {
                //add journey from 'Add batch' button in SiteVaccinesList or site-vaccine-batches page
                if ((addOrEdit?.IsAddBatchFromSiteVaccinesList || addOrEdit?.IsAddBatchFromSiteBatchesList || addOrEdit?.IsEditBatch) && otherBatchNumber && vaccineProgramId.toString() !== SiteVaccinesContants.COVID19_ID.toString() && siteVaccineBatches?.length > 0) {
                    CheckOtherBatchNumberExistsInSite(batch?.VaccineId, batch?.Site, otherBatchNumber, siteVaccineBatches);
                }
                //add journey from 'Add vaccine' button in SiteVaccinesList page
                if (((addOrEdit?.IsAddBatch && !addOrEdit?.IsAddBatchFromSiteVaccinesList && !addOrEdit?.IsAddBatchFromSiteBatchesList) || addOrEdit?.IsEditBatch) && otherBatchNumber && vaccineProgramId.toString() !== SiteVaccinesContants.COVID19_ID.toString() && siteVaccines?.length > 0) {
                    siteVaccines?.filter(sv => sv.VaccineProgramId === vaccineProgramId).forEach((siteVaccine: SiteVaccines) => {
                        CheckOtherBatchNumberExistsInSite(batch?.VaccineId, batch?.Site, otherBatchNumber, siteVaccine?.SiteVaccineBatches);
                    })
                }
                break;
            }
        }
    }

    const invalidBatchNumberFormatWarning =
        <>
            {showInvalidBatchFormatWarning ?
                <div className="nhsuk-warning-callout">
                    < h3 className="nhsuk-warning-callout__label" >
                        Warning < span className="nhsuk-u-visually-hidden" >:</span>
                    </h3 >
                    <p>Batch number doesn't conform to the expected format.</p>
                </div >
                : ''
            }
        </>

    return (
        <>
            {location && location.state ?
                <>
                    {!addOrEdit ?
                        <>
                            <div className="nhsuk-back-link">
                                <Link className="nhsuk-back-link__link" to={{ pathname: SiteVaccinesPaths.AddVaccine }}
                                    state={[options, userSites, siteVaccines, batch]}><svg className="nhsuk-icon nhsuk-icon__chevron-left" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" aria-hidden="true" height="24" width="24">
                                        <path d="M8.5 12c0-.3.1-.5.3-.7l5-5c.4-.4 1-.4 1.4 0s.4 1 0 1.4L10.9 12l4.3 4.3c.4.4.4 1 0 1.4s-1 .4-1.4 0l-5-5c-.2-.2-.3-.4-.3-.7z"></path>
                                    </svg> Back
                                </Link>
                            </div>
                        </>
                        :
                        <>
                            {addOrEdit?.IsAddEditBatchFromSiteBatchesList === true ?
                                <div className="nhsuk-back-link">
                                    <Link className="nhsuk-back-link__link" to={{ pathname: SiteVaccinesPaths.SiteVaccineBatchesList }}
                                        state={[options, userSites, siteVaccines, siteVaccine]}><svg className="nhsuk-icon nhsuk-icon__chevron-left" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" aria-hidden="true" height="24" width="24">
                                            <path d="M8.5 12c0-.3.1-.5.3-.7l5-5c.4-.4 1-.4 1.4 0s.4 1 0 1.4L10.9 12l4.3 4.3c.4.4.4 1 0 1.4s-1 .4-1.4 0l-5-5c-.2-.2-.3-.4-.3-.7z"></path>
                                        </svg> Back
                                    </Link>
                                </div>
                                :
                                <>
                                    {addOrEdit?.IsAddEditBatchFromSiteVaccinesList === true &&
                                        <div className="nhsuk-back-link">
                                            <Link className="nhsuk-back-link__link" to={{ pathname: SiteVaccinesPaths.SiteVaccinesList }}
                                                state={[null, [] as SiteVaccines[]]}><svg className="nhsuk-icon nhsuk-icon__chevron-left" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" aria-hidden="true" height="24" width="24">
                                                    <path d="M8.5 12c0-.3.1-.5.3-.7l5-5c.4-.4 1-.4 1.4 0s.4 1 0 1.4L10.9 12l4.3 4.3c.4.4.4 1 0 1.4s-1 .4-1.4 0l-5-5c-.2-.2-.3-.4-.3-.7z"></path>
                                                </svg> Back
                                            </Link>
                                        </div>
                                    }
                                </>
                            }
                        </>
                    }

                    <div className="nhsuk-grid-row mt-3">
                        <div className="nhsuk-grid-column-two-thirds">
                            {((formik.touched.CovidBatchNumberPrefix && formik.errors.CovidBatchNumberPrefix) 
                                || (formik.touched.CovidBatchNumberSuffix && formik.errors.CovidBatchNumberSuffix)
                                || (formik.touched.OtherBatchNumber && formik.errors.OtherBatchNumber)
                                || (formik.touched.ExpiryDate_1 && formik.errors.ExpiryDate_1)
                                || (formik.touched.ExpiryDate_2 && formik.errors.ExpiryDate_2)
                                || (formik.touched.ExpiryDate_3 && formik.errors.ExpiryDate_3)
                                || batchExistsError) &&
                                <>
                                    <div className="nhsuk-error-summary" aria-labelledby="error-summary-title" role="alert">
                                        <h2 className="nhsuk-error-summary__title" id="error-summary-title">
                                            There is a problem
                                        </h2>
                                        <div className="nhsuk-error-summary__body">
                                            <ul className="nhsuk-list nhsuk-error-summary__list">
                                                {batchExistsError &&
                                                <li className="nhsuk-error-message"><Link to='#' onClick={((e) => { HandleErrorFocusOnClickByName(e, 'CovidBatchNumberPrefix') })}>Batch number already exists</Link></li>
                                                }

                                            {(formik.touched.CovidBatchNumberPrefix && formik.errors.CovidBatchNumberPrefix) &&
                                                <li className="nhsuk-error-message"><Link to='#' onClick={((e) => { HandleErrorFocusOnClickByName(e, 'CovidBatchNumberPrefix') })}>{formik.errors.CovidBatchNumberPrefix}</Link></li>
                                                }

                                            {(formik.touched.CovidBatchNumberSuffix && formik.errors.CovidBatchNumberSuffix) &&
                                                <li className="nhsuk-error-message"><Link to='#' onClick={((e) => { HandleErrorFocusOnClickByName(e, 'CovidBatchNumberSuffix') })}>{formik.errors.CovidBatchNumberSuffix}</Link></li>
                                                }

                                            {(formik.touched.OtherBatchNumber && formik.errors.OtherBatchNumber) &&
                                                <li className="nhsuk-error-message"><Link to='#' onClick={((e) => { HandleErrorFocusOnClickByName(e, 'OtherBatchNumber') })}>{formik.errors.OtherBatchNumber}</Link></li>
                                                }

                                            {(formik.touched.ExpiryDate_1 && formik.errors.ExpiryDate_1) &&
                                                <li className="nhsuk-error-message"><Link to='#' onClick={((e) => { HandleErrorFocusOnClickByName(e, 'ExpiryDate_1') })}>{formik.errors.ExpiryDate_1}</Link></li>
                                                }

                                            {(formik.touched.ExpiryDate_2 && formik.errors.ExpiryDate_2) &&
                                                <li className="nhsuk-error-message"><Link to='#' onClick={((e) => { HandleErrorFocusOnClickByName(e, 'ExpiryDate_2') })}>{formik.errors.ExpiryDate_1}</Link></li>
                                                }

                                            {(formik.touched.ExpiryDate_3 && formik.errors.ExpiryDate_3) &&
                                                <li className="nhsuk-error-message"><Link to='#' onClick={((e) => { HandleErrorFocusOnClickByName(e, 'ExpiryDate_3') })}>{formik.errors.ExpiryDate_1}</Link></li>
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </>
                            }

                            <form onSubmit={formik.handleSubmit}>
                                <fieldset className="nhsuk-fieldset mb-3">
                                    <legend className="nhsuk-fieldset__legend nhsuk-fieldset__legend--xl">
                                        <h1 className="nhsuk-fieldset__heading">
                                            {addOrEdit?.IsEditBatch ? 'Edit batch' : 'Add batch'}
                                        </h1>
                                    </legend>
                                </fieldset>

                                <div className={"nhsuk-form-group " + ((formik.touched.CovidBatchNumberPrefix && formik.errors.CovidBatchNumberPrefix) ? "nhsuk-form-group--error" : (formik.touched.CovidBatchNumberSuffix && formik.errors.CovidBatchNumberSuffix) ? "nhsuk-form-group--error" : batchExistsError ? "nhsuk-form-group--error" : "")}>
                                    <fieldset className="nhsuk-fieldset" aria-describedby="add- batch-hint">
                                        {showCovidBatchNumberFields ?
                                            <>
                                                {invalidBatchNumberFormatWarning}
                                                <label className="nhsuk-label" htmlFor="CovidBatchNumberPrefix">
                                                    Batch number
                                                </label>
                                                <label className="nhsuk-label nhsuk-u-visually-hidden" htmlFor="CovidBatchNumberSuffix">
                                                    Batch number Suffix
                                                </label>

                                                <div className="nhsuk-hint" id="CovidBatchNumberPrefix-hint">
                                                    Please enter the COVID-19 batch number and thawed expiry date provided by your vaccine supplier. You can find this information on the vaccine vial box. For example {batch ? DisplayVaccineHintText(batch?.VaccineId) : vaccineHintText}
                                                </div>

                                                {formik.touched.CovidBatchNumberPrefix && formik.errors.CovidBatchNumberPrefix ?
                                                    <span key="CovidBatchNumberPrefixIdError" className="nhsuk-error-message" id="CovidBatchNumberPrefixIdError">
                                                        <span key="CovidBatchNumberPrefixIdVisuallyHiddenError" className="nhsuk-u-visually-hidden">Error:</span> {formik.errors.CovidBatchNumberPrefix}
                                                    </span> : ''
                                                }

                                                {formik.touched.CovidBatchNumberSuffix && formik.errors.CovidBatchNumberSuffix ?
                                                    <span key="CovidBatchNumberSuffixIdError" className="nhsuk-error-message" id="CovidBatchNumberSuffixIdError">
                                                        <span key="CovidBatchNumberSuffixIdVisuallyHiddenError" className="nhsuk-u-visually-hidden">Error:</span> {formik.errors.CovidBatchNumberSuffix ?? formik.touched.CovidBatchNumberSuffix}
                                                    </span> : ''
                                                }

                                                {batchExistsError ?
                                                    <span key="CovidBatchNumberAlreadyExistsError" className="nhsuk-error-message" id="CovidBatchNumberAlreadyExistsIdError">
                                                        <span key="CovidBatchNumberAlreadyExistsVisuallyHiddenError" className="nhsuk-u-visually-hidden">Error:</span> Batch number already exists
                                                    </span> : ''
                                                }

                                                <input
                                                    className={"nhsuk-input nhsuk-input--width-5 " + ((formik.touched.CovidBatchNumberPrefix && formik.errors.CovidBatchNumberPrefix) ? "nhsuk-input--error" : batchExistsError ? "nhsuk-input--error" : "")}
                                                    id="CovidBatchNumberPrefix"
                                                    name="CovidBatchNumberPrefix"
                                                    value={formik.values.CovidBatchNumberPrefix || ''}
                                                    type="text"
                                                    aria-describedby="CovidBatchNumberPrefix-hint"
                                                    onInput={(e) => { const target = e.target as HTMLTextAreaElement; target.value = ("" + target.value).toUpperCase() }}
                                                    onChange={(e) => { handleBatchNumberChange(e, { IsCovidBatchNumberPrefix: true, IsOtherBatchNumber: false, IsCovidBatchNumberSuffix: false }); formik.handleChange(e); }}
                                                    onBlur={(e) => { handleBatchNumberChange(e, { IsCovidBatchNumberPrefix: true, IsOtherBatchNumber: false, IsCovidBatchNumberSuffix: false }); formik.handleChange(e); }}
                                                />
                                                <span>  -  </span>

                                                <input 
                                                    className={"nhsuk-input nhsuk-input--width-4 " + ((formik.touched.CovidBatchNumberSuffix && formik.errors.CovidBatchNumberSuffix) ? "nhsuk-input--error" : batchExistsError ? "nhsuk-input--error" : "")}
                                                    id="CovidBatchNumberSuffix"
                                                    name="CovidBatchNumberSuffix" type="text"
                                                    value={formik.values.CovidBatchNumberSuffix || ''}
                                                    aria-describedby="CovidBatchNumberPrefix-hint"
                                                    onInput={(e) => { const target = e.target as HTMLTextAreaElement; target.value = ("" + target.value).toUpperCase() }}
                                                    onChange={(e) => { handleBatchNumberChange(e, { IsCovidBatchNumberPrefix: false, IsOtherBatchNumber: false, IsCovidBatchNumberSuffix: true }); formik.handleChange(e); }}
                                                    onBlur={(e) => { handleBatchNumberChange(e, { IsCovidBatchNumberPrefix: false, IsOtherBatchNumber: false, IsCovidBatchNumberSuffix: true }); formik.handleChange(e); }}
                                                />
                                            </>
                                            : ''
                                        }
                                    </fieldset>
                                </div>

                                {showOtherBatchNumberField ?
                                    <>
                                        <div className={"nhsuk-form-group " + (((formik.touched['OtherBatchNumber'] && formik.errors['OtherBatchNumber']) || batchExistsError) ? "nhsuk-form-group--error" : "")}>
                                            {invalidBatchNumberFormatWarning}

                                            <label className="nhsuk-label" htmlFor="OtherBatchNumber">
                                                Batch number
                                            </label>

                                            {formik.touched.OtherBatchNumber && formik.errors.OtherBatchNumber ?
                                                <span key="OtherBatchNumberIdError" className="nhsuk-error-message" id="OtherBatchNumberIdError">
                                                    <span key="OtherBatchNumberIdVisuallyHiddenError" className="nhsuk-u-visually-hidden">Error:</span> {formik.errors.OtherBatchNumber}
                                                </span> : ''
                                            }

                                            <input 
                                                className={"nhsuk-input nhsuk-input--width-10 " + (((formik.touched['OtherBatchNumber'] && formik.errors['OtherBatchNumber']) || batchExistsError) ? "nhsuk-input--error" : "")}
                                                id="OtherBatchNumber"
                                                name="OtherBatchNumber"
                                                value={formik.values.OtherBatchNumber || ''}
                                                type="text"
                                                onInput={(e) => { const target = e.target as HTMLTextAreaElement; target.value = ("" + target.value).toUpperCase() }}
                                                onChange={(e) => { handleBatchNumberChange(e, { IsCovidBatchNumberPrefix: false, IsOtherBatchNumber: true, IsCovidBatchNumberSuffix: false }); formik.handleChange(e); }}
                                                onBlur={(e) => { handleBatchNumberChange(e, { IsCovidBatchNumberPrefix: false, IsOtherBatchNumber: true, IsCovidBatchNumberSuffix: false }); formik.handleBlur(e); }}
                                            />
                                        </div>
                                    </>
                                    : ''
                                }

                                {showCovidBatchNumberFields || showOtherBatchNumberField ?
                                    <>
                                        <div className={"nhsuk-form-group " + ((formik.touched['ExpiryDate_1'] && formik.errors['ExpiryDate_1']) ? "nhsuk-form-group--error" : "")}>
                                            <hr></hr>
                                            <fieldset className="nhsuk-fieldset" aria-describedby="ExpiryDate-hint" role="group">
                                                {showCovidBatchNumberFields &&
                                                    <legend className="nhsuk-fieldset__legend">
                                                        Thawed expiry date
                                                    </legend>
                                                }

                                                {showOtherBatchNumberField &&
                                                    <legend className="nhsuk-fieldset__legend">
                                                        Expiry date
                                                    </legend>
                                                }
                                                <div className="nhsuk-hint" id="ExpiryDate-hint">
                                                    For example, 31 03 {new Date().getFullYear() + 1}
                                                </div>

                                                <div className="nhsuk-date-input" id="ExpiryDate">
                                                    {formik.touched.ExpiryDate_1 && formik.errors.ExpiryDate_1 &&
                                                        <span key="BatchExpiryDateIdErrorRequiredSpan2" className="nhsuk-error-message" id="BatchExpiryDateRequiredError2">
                                                            <span key="BatchExpiryDateErrorRequiredSpan2" className="nhsuk-u-visually-hidden">Error:</span> {formik.errors.ExpiryDate_1}
                                                        </span>
                                                    }

                                                    <div className="nhsuk-date-input__item">
                                                        <div className="nhsuk-form-group">
                                                            <label className="nhsuk-label nhsuk-date-input__label" htmlFor="ExpiryDate_1">
                                                                Day
                                                            </label>

                                                            <input className={"nhsuk-input nhsuk-date-input__input nhsuk-input--width-2 " + ((formik.touched['ExpiryDate_1'] && formik.errors['ExpiryDate_1']) ? "nhsuk-input--error" : "")}
                                                                id="ExpiryDate_1" name="ExpiryDate_1" type="number" inputMode="numeric"
                                                                value={formik.values.ExpiryDate_1}
                                                                placeholder="DD"
                                                                maxLength={2}
                                                                onChange={(e) => { formik.handleChange(e); }}
                                                                onBlur={(e) => { formik.handleBlur(e); }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="nhsuk-date-input__item">
                                                        <div className="nhsuk-form-group">
                                                            <label className="nhsuk-label nhsuk-date-input__label" htmlFor="ExpiryDate_2">
                                                                Month
                                                            </label>

                                                            <input className={"nhsuk-input nhsuk-date-input__input nhsuk-input--width-2 " + ((formik.touched['ExpiryDate_1'] && formik.errors['ExpiryDate_1']) ? "nhsuk-input--error" : "")}
                                                                id="ExpiryDate_2" name="ExpiryDate_2" type="number" inputMode="numeric"
                                                                value={formik.values.ExpiryDate_2}
                                                                placeholder="MM"
                                                                maxLength={2}
                                                                onChange={(e) => { formik.handleChange(e); }}
                                                                onBlur={(e) => { formik.handleBlur(e); }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="nhsuk-date-input__item">
                                                        <div className="nhsuk-form-group">
                                                            <label className="nhsuk-label nhsuk-date-input__label" htmlFor="ExpiryDate_3">
                                                                Year
                                                            </label>

                                                            <input className={"nhsuk-input nhsuk-date-input__input nhsuk-input--width-4 " + ((formik.touched['ExpiryDate_1'] && formik.errors['ExpiryDate_1']) ? "nhsuk-input--error" : "")}
                                                                id="ExpiryDate_3" name="ExpiryDate_3" type="number" inputMode="numeric"
                                                                value={formik.values.ExpiryDate_3}
                                                                placeholder="YYYY"
                                                                maxLength={4}
                                                                onChange={(e) => { formik.handleChange(e); }}
                                                                onBlur={(e) => { formik.handleBlur(e); }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </fieldset>
                                        </div>
                                    </> : ''
                                }

                                <div className="mt-3">
                                    <Button type="submit" className="nhsuk-button">{addOrEdit && addOrEdit?.IsEditBatch ? <> Confirm</> : <>Continue</>}</Button>
                                </div>

                            </form>
                        </div>
                    </div>
                </>
                :
                redirect()
            }
        </>
    )

    function CheckBatchNumberValid(vaccineProgramId: number, vaccineId: number, data: any, covidBatchNumberPrefixValue: string) {
        switch (vaccineProgramId) {
            case (SiteVaccinesContants.COVID19_ID): {
                if (IsInvalidBatch(vaccineId, data.IsCovidBatchNumberPrefix ? covidBatchNumberPrefixValue : '')) {
                    setShowInvalidBatchFormatWarning(true);
                }
                else {
                    setShowInvalidBatchFormatWarning(false);
                }
                break;
            }
        }
    }
}