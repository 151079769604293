import { appAxios } from '../../../_shared/axios';
import { OrganisationORD } from '../../../components/regional/models/organisation.models';

export class RegionalService {
  
  private basePath = 'api/Regional/';

  async searchOrganisations$(searchTerm: string, isODSSearch: boolean = false): Promise<OrganisationORD[]> {
    return (await appAxios.get(`${this.basePath}SearchOrganisations?searchTerm=${searchTerm}`)).data.Organisations;
  }

  static get instance() { return regionalService }
}

const regionalService = new RegionalService();

export default regionalService;