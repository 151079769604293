import { VaccineIds } from "../../_shared/shared.enums";

export function GetTotalVaccines(siteVaccines) {
    let covidVaccinesCount = 0;
    let fluVaccinesCount = 0;
    let totalVaccinesCount = 0;

    siteVaccines.forEach((value, index) => {
        covidVaccinesCount = covidVaccinesCount + value?.CovidVaccineIds?.length;
        fluVaccinesCount = fluVaccinesCount + value?.FluVaccineIds?.length;
    });
    totalVaccinesCount = covidVaccinesCount + fluVaccinesCount;
    return totalVaccinesCount;
}

export function DisplayVaccineHintText(vaccineId: number): string | null {
    const text = "For example, ";
    const format1 = "XX1234";
    const format2 = "123456X";
    const format3 = "XX1234X";
    const format4 = "-Y56";

    let textResult = '';

    switch (vaccineId) {
        case (VaccineIds.PfizerCovidOriginalOmicronBA4to5):
            textResult = `${text} ${format1}${format4}`;
            break;

        case (VaccineIds.PfizerComirnatyOmicronXBBAge12Plus):
            textResult = `${text} ${format1}${format4}`;
            break;

        case (VaccineIds.PfizerComirnatyOmicronXBB6monthsTo4Years):
            textResult = `${text} ${format1}${format4}`;
            break;

        case (VaccineIds.PfizerComirnatyOmicronXBB5To11Years):
            textResult = `${text} ${format1}${format4}`;
            break;

        case (VaccineIds.ModernaSpikevaxXBB):
            textResult = `${text} ${format2}${format4} or ${format3}${format4}`;
            break;

        default:
            return textResult;
    }

    return textResult;
}