import React from 'react'
import { PageTitles } from '../../_shared/shared.enums';
import { Category } from '../analytics/analytics.models';
import useAnalytics from '../analytics/hooks/useAnalytics';
import useDocumentTitle from '../../_shared/hooks/useDocumentTitle';


export default function ContactUs() {
    useAnalytics({ primaryCategory: "contact-us" } as Category);
    useDocumentTitle(PageTitles.ContactUs);
  
  return (
  <fieldset className="nhsuk-fieldset">
    <legend className="nhsuk-fieldset__legend nhsuk-fieldset__legend--l">
      <h1 className="nhsuk-fieldset__heading">
          Contact us
      </h1>
      </legend>
      <p className="nhsuk-label">Contact our helpdesk team if you need help to:</p>
      <div className="nhsuk-u-margin-left-4">
        <ul className="nhsuk-list--bullet">
          <li>request user access by providing a list of users including their:
            <ul className="nhsuk-list--bullet nhsuk-u-margin-left-5" style={{ listStyleType: 'circle' }}>
              <li>name</li>
              <li>email address</li>
              <li>professional body and registration number (if they are a clinician)</li>
            </ul>
          </li>
          <li>request vaccination data reports</li>
          <li>troubleshoot or get support</li>
        </ul>
      </div>
      <p>Telephone: 0121 611 0187 (select option 3 for the RAVS service desk)</p>
      <p>Email: <a className="nhsuk-link" href="mailto:ravs.support@england.nhs.uk">ravs.support@england.nhs.uk</a></p>
      <p>Monday to Friday: 8am to 6pm. Weekends: 8am to 4pm</p>
    </fieldset>
  );
};
