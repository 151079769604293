import { appAxios } from '../../_shared/axios';
import { PageType } from '../../_shared/shared.enums';
import { Option } from '../../_shared/shared.models';
import { Vaccination, VaccinationOptions } from './vaccination.models';

export class VaccinationService {
  private basePath = 'api/vaccination/';
  private options: string;
  private lastVaccination: Vaccination;
  private includeDeleted: boolean | null = null;

  async options$(includeDeleted: boolean = false): Promise<VaccinationOptions> {
      if (this.options && (this.includeDeleted === includeDeleted || this.includeDeleted === null)) {
        this.includeDeleted = includeDeleted;
        return JSON.parse(this.options);
      }
      return await appAxios.get(`${this.basePath}options?includeDeleted=${includeDeleted}`)
        .then(res => {
          this.options = JSON.stringify(res.data);
          this.includeDeleted = includeDeleted;
          return res.data 
        });
  }

  async vaccines$(siteId): Promise<Option[]> {
    return await appAxios.get(`${this.basePath}vaccines?siteId=${siteId}`)
      .then(res => {
        return res.data 
      });
  }

  async get$(id: string): Promise<Vaccination> {
    return await appAxios.get(`${this.basePath}get?id=${id}`)
    .then(res => {
      for (var i in res.data) {
        res.data[i] = res.data[i]?.toString();
      }
      return res.data 
    });
  }

  async addOrEdit$(pageType, vaccination: Vaccination): Promise<Vaccination> {
    if (pageType === PageType.Add)  this.lastVaccination = vaccination;

    for (var i in vaccination) {
      if (!vaccination[i]) vaccination[i] = null;
    }
    
    return await appAxios.post(`${this.basePath}${pageType}`, vaccination)
    .then(res => {
      return res.data 
    });
  }

  async delete$(id: any): Promise<any> {
    return await appAxios.get<any>(`${this.basePath}Delete?id=${id}`)
    .then(res => {
      return res.data 
    });
  }

  getLastVaccination(): Vaccination {
    return this.lastVaccination;
  }

  static get instance() { return vaccinationService }
}

const vaccinationService = new VaccinationService();

export default vaccinationService;
